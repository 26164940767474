import React, { useState } from 'react';
import './todo.css';

const Todo = () => {
    const [value, setValue] = useState("");
    const [list, setList] = useState([]);
    const [editButton, setEditButton] = useState(true);
    const [EdtItem, setEditItem] = useState(null);
    const AddItem = () => {
        if(value === ""){
            alert("Input box is empty !");
        }
        else{
            const InputData = {id: new Date().getTime().toString(), name: value}
            setList([...list, InputData]);
            setValue("");
        }
    }
    const deleteItem = (id) => {
        console.log("id is: "+id);
        const updatedlist = list.filter((elem)=>{
            return(
                id !== elem.id
            )
        });
        setList(updatedlist);
    }
    const editItem = (id) => {
        console.log("id is: "+id);
        const updatedlist1 = list.find((elem)=>{
            return(
                id === elem.id
            )
        });
        setValue(updatedlist1.name);
        setEditButton(false);
        setEditItem(id);
        console.log(updatedlist1);
    }
    const reWriteiTem = () =>{
        console.log("Rewrite awoke."+EdtItem);
        setList(list.map((elem)=>{
            if(elem.id === EdtItem){
                return {...elem, name:value}
            }
            return elem;
        }));
        setEditButton(true);
        setValue("");
        setEditItem(null);
    }
    return (
        <div className='todoList'>
            <div className="adjustmentCenter">
                <div className="center">
                    <div className="Header">
                        <h1>Todo List</h1>
                        <small>Add your list here</small>
                    </div>
                    <div className="body">
                        <input type="text" value={value} onChange={(e)=> setValue(e.target.value)} name="List" id="List" placeholder="✍️ Add item ..."/>
                        {
                            editButton ? <button onClick={AddItem}>+</button> : <button onClick={reWriteiTem}>✍️</button>
                        }
                    </div>
                    <div className="list">
                        {
                            list.map((elem) => {
                                return (
                                    <div className="item" key={elem.id}>
                                        <p>{elem.name}</p>
                                        <button onClick={()=> editItem(elem.id)}>Edit</button>
                                        <button onClick={() => deleteItem(elem.id)}>⛔</button>
                                    </div> 
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
      )
}

export default Todo;