// import logo from './logo.svg';
import './App.css';
import Todo from './components/Todo';

function App() {
  return (
    <div className="App-header">
      <Todo /> 
    </div>
  );
}

export default App;
